<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card class="pa-6 secondary">
      <v-card-title :class="`kda-ts-${g_bLowerBr ? 16 : 24}pt wt-extrabold d-flex justify-center`">
        {{ strTitle }}
      </v-card-title>

      <v-card-text :class="`kda-ts-${g_bLowerBr ? 12 : 16}pt`">
        {{ strText }}
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          :x-large="!g_bLowerBr"
          color="main-r-400 text-capitalize white--text"
          @click="show = false"
        >
          {{ strBtnLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDASubmittedModal',

  mixins: [BaseBreakpoint],

  props: {
    value: Boolean,
    strBtnLabel: {
      type: String,
      default: 'Ok'
    },
    strTitle: {
      type: String,
      default: 'Success'
    },
    strText: {
      type: String,
      default: 'The form has been successfully submitted'
    },
    textColor: {
      type: String,
      default: 'grey'
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<!--
USAGE IN THE PARENT COMPONENT

<template>
   <v-btn @click.stop="showSubmittedModal=true">
   <k-d-a-submitted-modal v-model="showSubmittedModal" @close="showSubmittedModal=false"/>
</template>

<script>

export default {
  data () {
    return {
      showSubmittedModal: false
    }
  },
  components: {
    KDASubmittedModal: () => import('@/components/KDASubmittedModal'),
  }
}
</script>

!-->
